import api from "@/service/api";
import utils from "@/service/utils";


export default {
    namespace: true,
    state: {
        sform: {
            sf_id: 0,
            sf_uniq: '',
            sf_date: '',
            sf_no: '',
            status_id: 0,
            sf_desc: '',
            customer_id: '0',
            config_send_notification: 0,
            config_customer_access_report: 0,
            created_props: '',
            updated_props: '',
            deleted_props: ''
        },
        customer: null,
        customer_search_list: [],
        delivery: {
            d_id: 0,
            sd_uniq: '',
            sf_id: 0,
            task_id: 0,
            customer_id: '0',
            device_id: "0",
            type: "take", // send | take
            method: "service_vehicle", // service_vehicle | cargo | short_distance
            date_tobe_at_address: "",
            delivery_address: "",
            district_id: "0",
            city_id: "34",
            location: "",
            staff_id_will_make: '', // işlemi gerçekleştirecek personel
            staff_id_maked: 0,
            vehicle_id_will_make: 0,//kullanılacak servis aracı
            staff_assign_task: 0,//personele görev olarak ata

            reason_id: 0, // müşteriden cihaz alımı yada gönderim nedeni

            fee_delivery: 0, //teslimat ücreti
            fee_delivery_currency_id: "1311",
            fee_service: 0,
            fee_service_currency_id: "1311",

            cargo_issue_date: '', //kargoya veriliş tarihi cihaz alımı ise müşteri kargoya verdi , gönderim ise firma kargoya verdi
            cargo_receipt_date: '', // kargodan alınış tarihi
            cargo_trackno: '',
            cargo_staff_id: "0",
            cargo_company_id: 0,
            cargo_fee: '',
            cargo_fee_currency_id: "1311",
            cargo_customer_notes: '', //müşteri kargoyu gönderdiğindeki notları,
            cargo_notes: '',
            status: '',
            cancel_notes: '',
            notification_sms_text:'',
            notification_sms_definition_id:null

        },
        delivery_list: [],
        task: {
            task_id: 0,
            sf_id: 0,
            device_id: "0",
            delivery_id: 0,
            staff_id: "0",
            complated_staff_id: "0",
            task_type: '',
            planned_date: '',
            complated_date: '',
            status: '',
            priority_id: "1396",
            related_group: '',
            related_group_id: 0,
            related_entity: '',
            related_entity_id: 0,
            task_definition_id: '0',
            task_notes: '',
            task_cancel_notes: '',
            created_props: '',
            updated_props: '',
            deleted_props: '',
            delivery:
            {
                reason_id: -1,
                delivery_address: '',
                district_id: "0",
                city_id: "34",
                location: '',
                fee_willbe_taken_fee: 0,
                fee_maked_taken_fee: 0,
                fee_willbe_taken_fee_currency_id: "1311",
                fee_maked_taken_fee_currency_id: "1311"
            }
            //optional
        },
        task_device_repair: {
            task_id: 0,
            sf_id: 0,
            device_id: "0",
            delivery_id: 0,
            staff_id: "0",
            complated_staff_id: "0",
            planned_date: '',
            complated_date: '',
            status: '',
            priority_id: "1396",
            related_group: '',
            related_group_id: 0,
            related_entity: '',
            related_entity_id: 0,
            task_definition_id: '0',
            task_notes: '',
            task_cancel_notes: '',
            created_props: '',
            updated_props: '',
            deleted_props: '',
            //optional
        },
        devices: [],
        device: {
            device_id: "0",
            device_uniq: utils.generateUniq({ size: 32 }),
            device_type: 'laptop',
            device_type_id: 1492,
            device_barcode: '',
            device_serial_number: '',
            brand_id: "0",
            model_name: '',
            customer_requests: [],//{ definition_id,definition_name,notes }
            customer_device_notes: '',
            pre_ekspertise_notes: '',///{ definition_id,definition_name,notes }
            pre_ekspertise: [],
            sf_id: 0,
            test_items: '',
            test_notes: '',
            test_approved_props: '',
            due_date: '',
            is_immediate: false,
            is_hasproblem:false,
            device_problems: [],
            //device_problem_tasks: [],
            device_actions: [],
            device_warrants: [],
            device_outsources: [],
            files: []
        },
        device_problem: {
            dp_id: 0,
            device_id: "0",
            issue: '',
            suggestion: '',
            status: 'pending',
            type: 'problem',
            tasks: [],
            created_props: '',
            updated_props: '',
            deleted_props: '',
            solution:'',
            todoaction:''
        },
        device_problem_task:
        {
            task_id: 0,
            sf_id: 0,
            device_id: "0",
            delivery_id: 0,
            staff_id: "0",
            complated_staff_id: "0",
            planned_date: '',
            complated_date: '',
            status: '',
            priority_id: null,
            related_group: '',
            related_group_id: 0,
            related_entity: '',
            related_entity_id: 0,
            task_definition_id: '0',
            task_notes: '',
            task_cancel_notes: '',
            created_props: '',
            updated_props: '',
            deleted_props: ''
        },
        service_informations: [],
        service_information: {
            di_id: 0,
            sf_id: 0,
            device_id: "0",
            task_id: 0,
            planned_date: '',
            complated_date: '',
            notes: '',
            staff_id: '',
            staff: '',
            task: '',
            status: 'pending',
            category_id: "0",
            assign_to_staff: false,
            show_to: [],
            channel_id: "0",
            created_props: '',
            updated_props: '',
            deleted_props: ''
        },
        device_action: {
            di_id: 0,
            sf_id: 0,
            device_id: "0",
            planned_date: '',
            complated_date: '',
            notes: '',
            staff_id: '',
            staff: '',
            task: '',
            status: '',
            assign_to_staff: false,
            show_to: [],
            channel_id: "0",
            created_props: '',
            updated_props: '',
            deleted_props: ''
        },
        device_warrant:
        {
            dw_id: 0,
            device_id: "0",
            warrant_type: '',
            start_date: '',
            end_date: '',
            notes: '',
            period: '',
            cancel_date: '',
            cancel_reason: '',
            created_props: '',
            updated_props: '',
            deleted_props: ''
        },
        device_outsource:
        {
            dos_id: 0,
            sf_id: 0,
            device_id: "0",
            customer_id: '0',
            status: '',
            reason_of_send: '',
            send_date: '',
            send_back_date: '',
            applied_desc: '',
            created_props: '',
            updated_props: '',
            deleted_props: ''
        },
        device_outsources: [],
        proposal:
        { 
            proposal_id: 0,
            sf_id: 0,
            proposal_no: 0,
            proposal_date: '',
            validty_date: '',
            bank_id: "0",
            user_id: "0",
            customer_id: '0',
            status_id: '1437',
            is_active: '0',
            kdv: 20,
            currency_type: 'TL',
            discount: '',
            discount_type: '',
            private_notes: '',
            show_total_price: false,
            show_kdv: false,
            show_discount: false,
            show_grand_total: false,
            proposal_footer_notes: "Sizinle ve ekibinizle çalışmak bir zevkti. Gelecekteki serbest projeleriniz için bizi hatırlayacağınızı umuyoruz. Teşekkür ederiz.",
            proposal_total: "",
            discount_total: "",
            sub_total: "",
            kdv_total: "",
            grand_total: "",
            created_props: '',
            updated_props: '',
            deleted_props: ''
        },
        proposals: [],
        proposal_item:
        {
            pi_id: 0,
            proposal_id: 0,
            notes: '',
            quantity: 1,
            price_unit: '',
            price_total: '',
            created_props: '',
            updated_props: '',
            deleted_props: ''
        },
        proposal_items: [],
        payments: [],
        payment:
        {
            p_id: 0,
            p_uniq: '',
            sf_id: 0,
            customer_id: '0',
            device_id: "0",
            category_id: "0",
            planned_date: '',
            complated_date: '',
            notes: '',
            status: 'pending',
            method_id: "1358",
            payment_link: '',
            amount: '',
            currency_id: "1311",
            created_props: '',
            updated_props: '',
            deleted_props: ''
        },
        filter:
        {
            service_list:
            {
                status_ids:["0"],
                keyword:'',
                keyword_type:'device',
                date_range:null,
                brand_id:'0',
                is_immediate:false,
                is_hasproblem:false,
                is_form_empty:false
            }
        }
    },
    mutations: {
        setSForm(state, data) {
            state.sform = data;
        },
        setSDevice(state,data)
        {
            state.device = data;
        },
        setSDelivery(state, data) {
            state.delivery = data;
        },
        setSDeliveryList(state, data) {
            state.delivery_list = data;
        },
        setDeliveryTask(state, data) {
            state.task = data;
        },
        setCustomerSearchList(state, data) {
            state.customer_search_list = data;
        },
        clearServiceDetails(state) {
            this.commit('clearSF');
            this.commit('clearDelivery');
            this.commit('clearTask');
            this.commit('clearDeviceProblem');
            this.commit('clearDeviceProblemTask');
            this.commit('clearCustomerInformation');
            this.commit('newDeviceWarrant');
            this.commit('newDevice');
            this.commit('newDeviceOutSource');
            this.commit('newProposal');
            this.commit('clearDeviceAction');
        },
        clearSF(state) {

            state.sform.sf_id = '0';
            state.sform.sf_uniq = '';
            state.sform.sf_date = '';
            state.sform.status = 'pending';
            state.sform.sf_desc = '';
            state.sform.customer_id = '0';
            state.sform.created_props = '';
            state.sform.updated_props = '';
            state.sform.deleted_props = '';
            state.customer = null;
 
            state.sform.sf_no =  utils.moment().format('DMYYHHMM')+utils.generateUniq({size:4}).toUpperCase();
            let d = utils.moment().format("YYYY-MM-DD");
            state.sform.sf_date = d;
     

        },
        clearDelivery(state) {

            state.delivery =
            {

                d_id: 0,
                sd_uniq: '',
                sf_id: 0,
                task_id: 0,
                customer_id: '0',
                device_id: "0",
                type: "take", // send | take
                method: "service_vehicle", // service_vehicle | cargo | short_distance
                date_tobe_at_address: "",
                delivery_address: "",
                district_id: "0",
                city_id: "34",
                location: "",
                staff_id_will_make: '', // işlemi gerçekleştirecek personel
                staff_id_maked: 0,
                vehicle_id_will_make: 0,//kullanılacak servis aracı
                staff_assign_task: 0,//personele görev olarak ata

                reason_id: 0, // müşteriden cihaz alımı yada gönderim nedeni

                fee_delivery: 0, //teslimat ücreti
                fee_delivery_currency_id: "1311",
                fee_service: 0,
                fee_service_currency_id: "1311",

                cargo_issue_date: '', //kargoya veriliş tarihi cihaz alımı ise müşteri kargoya verdi , gönderim ise firma kargoya verdi
                cargo_receipt_date: '', // kargodan alınış tarihi
                cargo_trackno: '',
                cargo_staff_id: "0",
                cargo_company_id: 0,
                cargo_fee: '',
                cargo_fee_currency_id: "1311",
                cargo_customer_notes: '', //müşteri kargoyu gönderdiğindeki notları,
                cargo_notes: '',
                status: '',
                cancel_notes: '',
                notification_sms_text:'',
                notification_sms_definition_id:null
            }
        },
        clearTask(state) {
            state.task = {
                task_id: 0,
                staff_id: "0",
                complated_staff_id: "0",
                task_type: '',
                planned_date: '',
                complated_date: '',
                status: '',
                priority_id: null,
                related_group: '',
                related_group_id: 0,
                related_entity: '',
                related_entity_id: 0,
                task_definition_id: '0',
                task_notes: '',
                task_cancel_notes: '',
                created_props: '',
                updated_props: '',
                deleted_props: '',
                delivery:
                {
                    reason_id: -1,
                    delivery_address: '',
                    district_id: "0",
                    city_id: "34",
                    location: '',
                    fee_willbe_taken_fee: 0,
                    fee_maked_taken_fee: 0,
                    fee_willbe_taken_fee_currency_id: "1311",
                    fee_maked_taken_fee_currency_id: "1311"
                }
            }
        },
        clearDeviceProblem(state) {
            state.device_problem =
            {
                dp_id: 0,
                device_id: "0",
                issue: '',
                suggestion: '',
                status: 'pending',
                type: 'problem',
                tasks: [],
                created_props: '',
                updated_props: '',
                deleted_props: '',
                solution:'',
                todoaction:''
            }
        },
        clearDeviceProblemTask(state) {
            state.device_problem_task =
            {
                task_id: 0,
                staff_id: "0",
                complated_staff_id: "0",
                planned_date: '',
                complated_date: '',
                status: '',
                priority_id: 0,
                related_group: '',
                related_group_id: 0,
                related_entity: '',
                related_entity_id: 0,
                task_definition_id: '0',
                task_notes: '',
                task_cancel_notes: '',
                created_props: '',
                updated_props: '',
                deleted_props: ''
            }
        },
        clearDeviceRepairTask(state) {
            state.task_device_repair =
            {
                task_id: 0,
                sf_id: 0,
                device_id: "0",
                delivery_id: 0,
                staff_id: "0",
                complated_staff_id: "0",
                planned_date: '',
                complated_date: '',
                status: '',
                priority_id: "1396",
                related_group: '',
                related_group_id: 0,
                related_entity: '',
                related_entity_id: 0,
                task_definition_id: '0',
                task_notes: '',
                task_cancel_notes: '',
                created_props: '',
                updated_props: '',
                deleted_props: '',
            }
        },
        clearCustomerInformation(state) {
            state.service_information = {
                di_id: 0,
                sf_id: 0,
                device_id: "0",
                task_id: 0,
                planned_date: '',
                complated_date: '',
                notes: '',
                staff_id: '',
                staff: '',
                task: '',
                status: 'pending',
                category_id: "0",
                assign_to_staff: false,
                show_to: [],
                channel_id: "0",
                created_props: '',
                updated_props: '',
                deleted_props: ''
            }
        },
        clearDeviceAction(state) {
            state.device_action =
            {
                di_id: 0,
                sf_id: 0,
                device_id: "0",
                planned_date: '',
                complated_date: '',
                notes: '',
                staff_id: '',
                staff: '',
                task: '',
                status: '',
                customer_report_type: '',
                assign_to_staff: false,
                show_to: [],
                channel_id: "0",
                created_props: '',
                updated_props: '',
                deleted_props: ''
            }
        },
        newDeviceWarrant(state) {

            state.device_warrant =
            {
                dw_id: 0,
                device_id: "0",
                warrant_type: '',
                start_date: '',
                end_date: '',
                notes: '',
                period: '',
                cancel_date: '',
                cancel_reason: '',
                created_props: '',
                updated_props: '',
                deleted_props: ''
            }
        },
        newDevice(state) {
            state.device =
            {
                device_id: "0",
                device_uniq: utils.generateUniq({ size: 32 }),
                device_type: 'laptop',
                device_type_id: 1492,
                device_barcode: '',
                device_serial_number: '',
                brand_id: "0",
                model_name: '',
                customer_requests: [],//{ definition_id,definition_name,notes }
                customer_device_notes: '',
                pre_ekspertise_notes: '',///{ definition_id,definition_name,notes }
                pre_ekspertise: [],
                sf_id: 0,
                test_items: '',
                test_notes: '',
                test_approved_props: '',
                due_date: '',
                is_immediate: false,
                is_hasproblem:false,
                staff_id:"0",
                backup_id:"0",
                status_id:"1471",//pending
                device_problems: [],
                //device_problem_tasks: [],
                device_warrants: [],
                files: []
            }
        },
        newDeviceOutSource(state) {
            state.device_outsource =
            {
                dos_id: 0,
                sf_id: 0,
                device_id: "0",
                customer_id: '0',
                status_id: 0,
                reason_of_send: '',
                send_date: '',
                send_back_date: '',
                applied_desc: '',
                created_props: '',
                updated_props: '',
                deleted_props: ''
            }
        },
        newProposal(state) {
            state.proposal =
            {
                proposal_id: 0,
                sf_id: 0,
                proposal_no: 0,
                proposal_date: '',
                validty_date: '',
                bank_id: "0",
                user_id: "0",
                customer_id: '0',
                status_id: '1437',
                is_active: '0',
                kdv: 20,
                currency_type: 'TL',
                discount: '',
                discount_type: '',
                private_notes: '',
                show_total_price: false,
                show_kdv: false,
                show_discount: false,
                show_grand_total: false,
                proposal_footer_notes: "Sizinle ve ekibinizle çalışmak bir zevkti. Gelecekteki serbest projeleriniz için bizi hatırlayacağınızı umuyoruz. Teşekkür ederiz.",
                proposal_total: "",
                discount_total: "",
                sub_total: "",
                kdv_total: "",
                grand_total: "",
                created_props: '',
                updated_props: '',
                deleted_props: '',
                show_panel: true,
                items:[]
            }
            state.proposal_items = []
        },
        newPayment(state) {
            state.payment =
            {
                p_id: 0,
                p_uniq: '',
                sf_id: 0,
                customer_id: '0',
                device_id: "0",
                category_id: "0",
                planned_date: '',
                complated_date: '',
                notes: '',
                status: '',
                method_id: "1358",
                payment_link: '',
                amount: '',
                currency_id: "1311",
                show_customer:'1',
                show_user:'0',
                created_props: '',
                updated_props: '',
                deleted_props: ''
            }
        }
    },
    actions: {
        saveSF(context) {

            let postData = context.state.sform
            postData = context.state.sform.config_customer_access_report ? 1 : 0;
            postData = context.state.sform.config_send_notification ? 1 : 0;

            return new Promise((resolve) => {
                api.action('saveSF', context.state.sform).then(q => {
                    resolve(q);
                    //context.commit("setSForm", q.data.sf);
                    //utils.notificationToast("success", "Bilgi", q.message);
                })
            })
        },
        createSF(context) {
       
            let postData = context.state.sform
            postData = context.state.sform.config_customer_access_report ? 1 : 0;
            postData = context.state.sform.config_send_notification ? 1 : 0;

            return new Promise((resolve, reject) => {
                if (context.state.sform.sf_no.length <= 3) {
                    utils.notificationToast('warning', 'Bilgi', 'Lütfen geçerli bir servis numarası yazın.');
                    return;
                }
                else {
                    api.action('saveSF', context.state.sform).then(q => {
                        resolve(q);
                    })
                }

            });
        },
        searchCustomer(context, data) {
            context.commit("setCustomerSearchList", []);
            if (data.keyword.length < 3) return;
            api.action("searchCustomer", data).then(q => {
                if (q.success) {
                    context.commit("setCustomerSearchList", q.data.customers);
                }
            })
        },
        getCustomerDevices(context, data) {
            return new Promise((resolve, reject) => {
                if (!data || !data.customer_id) resolve(null);
                let postData = { customer_id: data.customer_id }
                api.action('getCustomerDevices', postData).then(q => {
                    resolve(q);
                })
            })
        },
        saveDelivery({ state, commit, dispatch }) {

            let data = state.delivery;
            data.sf_id = state.sform.sf_id;

            if (data.type == 'take') {

                if (utils.isNullOrEmpty(data.reason_id)) {
                    utils.notificationToast('warning', 'Bilgi', 'Alım Nedenini Seçin');
                    return;
                }
                if (utils.isNullOrEmpty(data.district_id) && data.type == 'service_vehicle') {
                    utils.notificationToast('warning', 'Bilgi', 'İlçe Belirleyin');
                    return;
                }
            }
            if (data.type == 'send') {
                //müşteriden alım
                if (utils.isNullOrEmpty(data.reason_id) && data.type == 'service_vehicle') {
                    utils.notificationToast('warning', 'Bilgi', 'Gönderim Nedenini Seçin');
                    return;
                }
                if (utils.isNullOrEmpty(data.district_id) && data.type == 'service_vehicle') {
                    utils.notificationToast('warning', 'Bilgi', 'İlçe Belirleyin');
                    return;
                }
            }

            let postData = {}
            Object.keys(data).forEach(q => {

                let v = data[q];
                if (v === '') v = '';
                if (v === false) v = 0;
                postData[q] = v;

            })

            api.action('saveDelivery', postData).then(q => {
                if (q.success == false) {
                    utils.notificationToast("warning", "Bilgi", q.message);
                    return;
                }
                utils.notificationToast("success", "Bilgi", q.message);
                commit("setSDelivery", q.data);
                dispatch("getServiceDetails");
            })
        },
        getServiceDetails({ state, commit, dispatch }) 
        {
            api.action('getServiceDetails', {  sf_uniq: state.sform.sf_uniq }).then(q => {
                if (q.success) {
                    let data = Object.assign({},q.data);
                    console.log('gelendata',data);
                    if (data.sf != '') {
                        state.sform = q.data.sf;
                        utils.setTitle(`Servis » ${state.sform.sf_id}`);
                    }
                    if (data.customer != null) {
                        state.customer = q.data.customer;
                    }
                    if (data.service_informations != null && data.service_informations.length > 0) {
                        state.service_informations = data.service_informations;
                    }
                    if (data.delivery_list && data.delivery_list.length > 0) {

                        state.delivery_list = data.delivery_list;
                        if (state.task.task_id > 0) {
                            //seçili bir teslimat süreci varsa datayı tekrar güncelle teslimat görevleri için gerekli
 
                            if( data.tasks )
                            {
                                let item = data.tasks.find(q => q.task_id == state.task.task_id);
                                if (item != '') {
                                    state.task = item;
                                }
                            }
                     
                        }
                    }
      
                    if (data.devices && data.devices.length > 0) {
                        state.devices = data.devices;

                        if (state.device.device_uniq != '') {
                             
                            let findDevice = state.devices.find(qq => qq.device_uniq == state.device.device_uniq);
                            if (findDevice != null) {
                                state.device = findDevice;

                                if (state.device_problem.dp_id > 0) {
                                    let findDP = findDevice.device_problems.find(qq => qq.dp_id == state.device_problem.dp_id);
                                    if (findDP != '') {
                                        state.device_problem = findDP;

                                        let findDeviceProblemTask = ''
                                        if (state.device_problem_task.task_id > 0) {
                                            findDeviceProblemTask = findDP.tasks.find(qq => qq.task_id == state.device_problem_task.task_id);
                                        } else {
                                            findDeviceProblemTask = findDP.tasks.slice(-1)[0]
                                        }
                                        state.device_problem_task = findDeviceProblemTask;

                                    }
                                }
                            }
                        }
                    }

                    if (data.device_outsources && data.device_outsources.length > 0) {
                        state.device_outsources = data.device_outsources;
                    }

                    if (data.proposals && data.proposals.length > 0) {
                        state.proposals = data.proposals;

                        let findProposal = state.proposals.find(qq => qq.proposal_no == state.proposal.proposal_no);
                        if (findProposal != null) {
                            state.proposal = findProposal;
                        }
                    }

                    if (data.payments && data.payments.length > 0) {
                        state.payments = data.payments;
                    }

                } else {
                    utils.notificationToast('warning', 'Geçersiz Servis', 'Geçersiz bir servise erişmeyi denediniz. Bilgileri kontrol edip yeniden deneyin.');
                }
            })
        },
        addDeliveryTask({ state, commit, dispatch }) {
            if (state.task.planned_date == '') {
                utils.notificationToast("warning", "Bilgi", "Görev Tarihini Seçin");
                return;
            }
            if (state.task.priority_id <= 0) {
                utils.notificationToast("warning", "Bilgi", "Acileyet Derecesini Seçin");
                return;
            }
            if (state.task.task_definition_id <= 0) {
                utils.notificationToast("warning", "Bilgi", "Görev Ataması Yapın");
                return;
            }

            let postData = { ...state.task }
            postData.related_entity_id = state.delivery.d_id;
            postData.related_entity = 'delivery';
            postData.related_group = 'service';
            postData.related_group_id = state.sform.sf_id;
            api.action("addTaskDelivery", postData).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        saveDeliveryTask({ state, commit, dispatch }) 
        {
            if (['take', 'send'].indexOf(state.delivery.type) <= -1) {
                utils.notificationToast("warning", "Bilgi", "Gönderim türünü seçin.");
                return;
            }
            if (!state.task.status) {
                utils.notificationToast("warning", "Bilgi", "Görev durumunu seçin.");
                return;
            }
            if (state.task.planned_date.length <= 10) {
                utils.notificationToast("warning", "Bilgi", "Adreste bulunulması gereken tarihi seçin.");
                return;
            }
            if (!state.task.task_definition_id || state.task.task_definition_id <= 0) {
                utils.notificationToast("warning", "Bilgi", "Görev tanımını belirleyin.");
                return;
            }

            if (state.task.status == 'complated' && (!state.task.complated_staff_id || state.task.complated_staff_id <= 0)) {
                utils.notificationToast("warning", "Bilgi", "Lütfen görevi tamamlayan personeli seçin.");
                return;
            }
            let postData = { ...state.task }
            postData.sf_id = state.sform.sf_id;
            postData.delivery = state.delivery;
            postData.delivery_id = state.delivery.d_id;
            postData.device_id = postData.delivery.device_id;
     
            postData.delivery.customer_id = state.sform.customer_id;
            api.action("saveDeliveryTask", postData).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        updateTaskDelivery({ state, commit, dispatch }) {
            if (state.task.planned_date == '') {
                utils.notificationToast("warning", "Bilgi", "Görev Tarihini Seçin");
                return;
            }
            if (state.task.priority_id <= 0) {
                utils.notificationToast("warning", "Bilgi", "Acileyet Derecesini Seçin");
                return;
            }
            if (state.task.task_definition_id <= 0) {
                utils.notificationToast("warning", "Bilgi", "Görev Ataması Yapın");
                return;
            }

            let postData = { ...state.task }
            postData.related_entity_id = state.delivery.d_id;
            postData.related_entity = 'delivery';
            postData.related_group = 'service';
            postData.related_group_id = state.sform.sf_id;
            api.action("updateTaskDelivery", postData).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        cancelTaskDelivery({ state, commit, dispatch }) {
            let postData = { ...state.task }
            api.action("cancelTaskDelivery", postData).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        saveTask({ state, commit, dispatch }) 
        {
            if (!state.task.task_definition_id || state.task.task_definition_id <= 0) {
                utils.notificationToast("warning", "Bilgi", "Görev tanımını belirleyin.");
                return;
            }
            if (!state.task.planned_date || state.task.planned_date.length <= 10) {
                utils.notificationToast("warning", "Bilgi", "Görev tarhini seçin.");
                return;
            }

            let postData = { ...state.task }
            api.action("saveTask", postData).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);

                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        saveDeviceRepairTask({ state, commit, dispatch }, actiontype) {
            if (actiontype == 'canceled') {
                state.task_device_repair.status = 'canceled';
            }

            if (!state.task_device_repair.task_definition_id || state.task_device_repair.task_definition_id <= 0) {
                utils.notificationToast("warning", "Bilgi", "Görev tanımını belirleyin.");
                return;
            }
            if (!state.task_device_repair.planned_date || state.task_device_repair.planned_date.length <= 10) {
                utils.notificationToast("warning", "Bilgi", "Görev tarihini seçin.");
                return;
            }

            let postData = { ...state.task_device_repair }
            postData.task_type = 'device_repair';
            postData.sf_id = state.sform.sf_id;
            postData.device_id = state.device.device_id;

            api.action("saveDeviceRepairTask", postData).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                    
                }
            })
        },
        saveDevice({ state, commit, dispatch }) {


            if (state.device.device_type_id <= 0) {
                utils.notificationToast('warning', 'Bilgi', 'Cihaz türünü seçin.');
                return;
            }
            if (state.device.brand_id <= 0) {
                utils.notificationToast('warning', 'Bilgi', 'Cihazın markasını seçin.');
                return;
            }
            if (state.device.model_name.length <= 2) {
                utils.notificationToast('warning', 'Bilgi', 'Cihazın modeli yazın.');
                return;
            }

            let postData = { ...state.device };
            postData.sf_id = state.sform.sf_id;
            console.log('postData',postData);

            return new Promise( (resolve) => 
            {
                api.action('saveDevice', postData).then(q => {
                    if (q.success) {
                        utils.notificationToast('success', 'Bilgi', q.message);
                        dispatch("getServiceDetails");

                    } else {
                        utils.notificationToast('warning', 'Bilgi', q.message);
                    }
                    resolve(q);
                })
            });
        },
        saveDeviceStatus({ state, commit, dispatch }, device_status) {

            api.action('saveDeviceStatus', { ...device_status }).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
          
        },
        saveDeviceProblem({ state, commit, dispatch }) {

            if (state.device_problem.issue.length <= 5) {
                utils.notificationToast('warning', 'Bilgi', 'Lütfen geçerli bir sorun/problem yazın.');
                return;
            }
            state.device_problem.sf_id = state.sform.sf_id;
            state.device_problem.device_id = state.device.device_id;

            api.action('saveDeviceProblem', { ...state.device_problem }).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                    if (state.device_problem.dp_id == 0)
                        commit("clearDeviceProblem");

                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        saveDeviceProblemTask({ state, commit, dispatch }) {

            if (state.device_problem_task.task_notes == '' || state.device_problem_task.task_notes.length <= 5) {
                utils.notificationToast('warning', 'Bilgi', 'Lütfen geçerli bir görev yazın');
                return;
            }
            state.device_problem_task.related_group = 'service';
            state.device_problem_task.related_group_id = state.sform.sf_id;
            state.device_problem_task.related_entity = 'device_problem';
            state.device_problem_task.related_entity_id = state.device_problem.dp_id;

            api.action('saveDeviceProblemTask', { ...state.device_problem_task }).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                    if (state.device_problem_task.task_id == 0) {
                        commit("clearDeviceProblemTask");
                    }

                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        saveDeviceAction({ state, commit, dispatch }) {
            let postData = { ...state.device_action };
            postData.device_id = state.device.device_id;
            postData.sf_id = state.sform.sf_id;
            postData.device_actions = state.device.device_actions;
            console.log('postData',postData);
            api.action('saveDeviceAction', postData).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        saveDeviceTest({ state, commit, dispatch }) {
            api.action('saveDeviceTest', { ...state.device }).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        saveDeviceCustomerInformation({ state, commit, dispatch }) 
        {
            if (state.service_information.category_id <= 0) {
                utils.notificationToast("warning", "Bilgi", "Bilgilendirme kategorisi seçin.");
                return;
            }

            if (state.service_information.status == '') {
                utils.notificationToast("warning", "Bilgi", "Bilgilendirme durumunu seçin.");
                return;
            }

            if (state.service_information.channel_id <= 0) {
                utils.notificationToast("warning", "Bilgi", "Bilgilendirme aracını seçin.");
                return;
            }

            if (state.service_information.notes == '' || state.service_information.notes.length <= 5) {
                utils.notificationToast("warning", "Bilgi", "Lütfen geçerli bir not yazın.");
                return;
            }

            if (state.service_information.status == 'information_pending' && state.service_information.planned_date == '') {
                utils.notificationToast("warning", "Bilgi", "Lütfen bilgilendirmenin yapılacağı bir tarih seçin.");
                return;
            }

            if (state.service_information.status == 'information_complated' && state.service_information.complated_date == '') {
                utils.notificationToast("warning", "Bilgi", "Lütfen bilgilendirmenin yapıldığı tarihi seçin.");
                return;
            }

            state.service_information.sf_id = state.sform.sf_id;
            if (!state.service_information.device_id) {
                state.service_information.device_id = 0;
            }

            if (state.service_information.is_task) {
                if (state.task.task_definition_id == 0) {
                    utils.notificationToast("warning", "Bilgi", "Lütfen görev tanımı seçin.");
                    return;
                }
                if (state.task.priority_id == 0) {
                    utils.notificationToast("warning", "Bilgi", "Lütfen görevin öncelik derecesini seçin.");
                    return;
                }
            }

            let postData = { ...state.service_information }

            postData.task_definition_id = 0;
            postData.priority_id = 0;
            postData.is_task = postData.is_task ? 1 : 0;
            if (postData.is_task) {
                postData.task = state.task;
                postData.task.sf_id = state.sform.sf_id;
            }
            api.action("saveDeviceCustomerInformation", { ...postData }).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        saveDeviceWarrant({ state, commit, dispatch }) {
            if (state.device_warrant.warrant_type == '') {
                utils.notificationToast("warning", "Bilgi", "Garanti türünü seçin.");
                return;
            }
            if (state.device_warrant.start_date == '' || state.device_warrant.start_date == '') {
                utils.notificationToast("warning", "Bilgi", "Garanti başlangıç tarihini seçin.");
                return;
            }
            if (state.device_warrant.end_date == '' || state.device_warrant.end_date == '') {
                utils.notificationToast("warning", "Bilgi", "Garanti bitiş tarihini seçin.");
                return;
            }
            state.device_warrant.device_id = state.device.device_id;
            api.action("saveDeviceWarrant", {
                ...state.device_warrant
            }).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        saveDeviceOutsource({ state, commit, dispatch }) {

            if (!state.device_outsource.status || state.device_outsource.status == '') {
                utils.notificationToast("warning", "Bilgi", "Geçerli bir durum seçin.");
                return;
            }
            if (state.device_outsource.reason_of_send == '' || state.device_outsource.reason_of_send.length <= 10) {
                utils.notificationToast("warning", "Bilgi", "Geçerli bir gönderim nedeni yazın.");
                return;
            }
            if (state.device_outsource.send_date == '') {
                utils.notificationToast("warning", "Bilgi", "Gönderim tarihini yazın.");
                return;
            }

            let postData = { ...state.device_outsource };
            postData.sf_id = state.sform.sf_id;
            postData.device_id = state.device.device_id;
            api.action('saveDeviceOutsource', postData).then(q => {
                if (q.success == false) {
                    utils.notificationToast("warning", "Bilgi", q.message);
                    return;
                }
                utils.notificationToast("success", "Bilgi", q.message);
                dispatch("getServiceDetails");
            });

        },
        saveDeviceRequesMessage({ state, commit, dispatch },data) {
            return new Promise((resolve,reject) => {

                if(data.m_action == 'answer' && ( data.message_answer==null || data.message_answer.length <= 5))
                {
                  utils.notificationToast("warning", "Bilgi", 'Lütfen geçerli bir yanıt yazın.');
                  reject();
                  return;
                }
                api.action('saveDeviceRequesMessage', data).then(q => {
                    resolve(q);
                    //context.commit("setSForm", q.data.sf);
                    //utils.notificationToast("success", "Bilgi", q.message);
                })
            })
            return;
            api.action('saveDeviceTest', { ...state.device }).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        saveProposal({ state, commit, dispatch }) {
 
            let data = Object.assign({},state.proposal);
            console.log('proposal',data);
            if (data.proposal_date == '') {
                utils.notificationToast('warning', 'Lütfen teklif tarihini yazın.');
                return;
            }
            if (data.validty_date == '') {
                utils.notificationToast('warning', 'Lütfen teklifin geçerlilik tarihini yazın.');
                return;
            }
            if (data.user_id <= 0) {
                utils.notificationToast('warning', 'Teklifi düzenleyen personeli seçin.');
                return;
            }
 
            let postData = data;
            postData.show_total_price == true ? 1 : 0;
            postData.show_kdv == true ? 1 : 0;
            postData.show_discount == true ? 1 : 0;
            postData.show_grand_total == true ? 1 : 0;
 
            let items = Object.assign([],postData.items);
            postData.items = items;
            api.action("saveProposal", {
                ...postData
            }).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        savePayment({ state, commit, dispatch }) {

            if (state.payment.category_id <= 0) {
                utils.notificationToast('warning', 'Bilgi', 'Lütfen ödeme kategorisini seçin.');
                return;
            }
            if (state.payment.planned_date == '') {
                utils.notificationToast('warning', 'Bilgi', 'Ödemenin yapılması gereken tarihi seçin.');
                return;
            }
            if (state.payment.amount <= 1) {
                utils.notificationToast('warning', 'Bilgi', 'Ödeme miktarını yazın.');
                return;
            }
            if (state.payment.status == '') {
                utils.notificationToast('warning', 'Bilgi', 'Ödeme durumunu seçin.');
                return;
            }
            let postData = state.payment;
            postData.customer_id = state.customer.customer_id;
            postData.sf_id = state.sform.sf_id;

            api.action("savePayment", { ...postData }).then(q => {
                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    dispatch("getServiceDetails");
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })

        },
        downloadProposal({ state, commit, dispatch },downloadFileName) {
 
            if (state.proposal.proposal_id <= 0) {
                utils.notificationToast("warning", "Bilgi", "Teklif Seçin");
                return;
            }

            let postData = state.proposal
            api.action("downloadProposal", { ...postData }).then(q => {

                if (q.success) {
                    utils.notificationToast("success", "Bilgi", q.message);
                    api.downloadFile(q.data.filename,downloadFileName);
                } else {
                    utils.notificationToast("warning", "Bilgi", q.message);
                }
            })
        },
        deleteFile({ state, commit, dispatch }, file) {

            if (file == '') return;
            let postData = { ...file }
            api.action('removeFile', postData).then(q => {
                if (q.success == false) {
                    utils.notificationToast("warning", "Bilgi", q.message);
                    return;
                }
                utils.notificationToast("success", "Bilgi", q.message);
                dispatch("getServiceDetails");
            })
        },
        sendSMSNotification({ state, commit, dispatch }, data) {
       
            api.action('sendSMSNotification', data).then(q => {
                if (q.success == false) {
                    utils.notificationToast("warning", "Bilgi", q.message);
                    return;
                }
                else {
                    utils.notificationToast("success", "Bilgi", "Mesaj Gönderildi");
                }
            })
        }
    },
    getters: {
        getSForm(state) { return state.sform; },
        getsDelivery(state) { return state.delivery },
        getSDeliveryList(state) { return state.delivery_list },
        getDeliveryTasks(state) {
            let result = [];
            if (state.delivery != '' && state.delivery.sd_uniq != '' && state.delivery.tasks != '') {
                result = state.delivery.tasks;
            }
            return result;
        }
    }
}